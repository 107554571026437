import React, { useEffect, useRef, useState } from "react";
import { Button } from "../index";
import Questionnaire, {
    extractQuestionnaireErrors,
    buildQuestionArray,
    QuestionnaireError,
} from "../../models/questionnaire";
import Question, { extractQuestionError } from "../../models/question";
import Answer from "../../models/answer";
import useLanguage from "../../hooks/language";

import QuestionBlock from "./question-block";
import Tracker from "./tracker";

import styles from "./questionnaire.module.css";

interface QuestionnaireFormProps {
    questionnaire: Questionnaire;
    onCompleteQuestionnaire: (questionnaire: Questionnaire) => void;
    loading?: boolean;
}

const QuestionnaireForm: React.FunctionComponent<QuestionnaireFormProps> = ({
    questionnaire,
    onCompleteQuestionnaire,
    loading = false,
}: QuestionnaireFormProps) => {
    const { translations } = useLanguage();
    const t = translations.questionnaireComponent;
    const containerRef = useRef<HTMLDivElement | null>(null);
    const lastManualFocusTime = useRef(Date.now());
    const [focusedIndex, setFocusedIndex] = useState<string>();
    const questionsRefMap = useRef<Record<string, HTMLElement | null>>({});

    // States

    const [submitted, setSubmitted] = useState(false);
    const [_questionnaire, setQuestionnaire] = useState(questionnaire);
    const questions = buildQuestionArray(_questionnaire);
    const errors = extractQuestionnaireErrors(_questionnaire, translations);

    const onAnswerChange = ({ id, type }: Question, answer: any | Answer[]) => {
        const updatedQuestions = _questionnaire.questions.map((question) => {
            if (question.id === id) {
                if (question.type === "checkbox" && Array.isArray(answer)) {
                    return { ...question, selectedAnswers: answer };
                } else {
                    return { ...question, answer };
                }
            }
            return question;
        });

        // Manually update questions array to maintain consistent state
        // Prevent navigation conflicts across multiple state sources
        // To apply a better solution, a full refactor is needed
        const newQuestionsList = buildQuestionArray({
            ..._questionnaire,
            questions: updatedQuestions,
        });

        const currentQuestionIndex = newQuestionsList.findIndex(
            (question) => question.id === id
        );

        if (
            type === "radio" &&
            currentQuestionIndex < newQuestionsList.length - 1 &&
            newQuestionsList[currentQuestionIndex + 1]
        ) {
            // Mitigate race condition in state updates by using setTimeout
            // Multiple state changes and async setState calls can cause
            // unexpected behavior when scrolling after answer selection
            // To apply a better solution, a full refactor is needed
            const nextQuestionIndex =
                newQuestionsList[currentQuestionIndex + 1]?.index;
            setTimeout(() => {
                scrollToElement(nextQuestionIndex);
            }, 250);
        }
        setQuestionnaire({ ..._questionnaire, questions: updatedQuestions });
    };

    const scrollToPosition = (targetPosition: number) => {
        const targetIndex = questions[targetPosition]?.index;
        if (targetIndex != focusedIndex) {
            scrollToElement(targetIndex);
        }
    };

    const scrollToElement = (targetId: string) => {
        const questionElement = document.getElementById(`index-${targetId}`);

        questionElement?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
        const focusableChild = questionElement?.querySelector<HTMLElement>(
            "input, textarea"
        );
        if (focusableChild) {
            focusableChild.focus({ preventScroll: true });
        }

        setFocusedIndex(targetId);
        lastManualFocusTime.current = Date.now();
    };

    const onSubmitForm = () => {
        setSubmitted(true);
        if (errors.length > 0) return;

        onCompleteQuestionnaire(_questionnaire);
    };

    // Rendering

    const showErrors = submitted && errors.length > 0;

    const renderError = ({
        error,
        questionIndex,
        index,
    }: QuestionnaireError) => {
        return (
            <a onClick={() => scrollToPosition(index)}>
                {questionIndex}. {error}
            </a>
        );
    };

    const ramqExpDateQuestion = questions.find(
        (question) => question.key == "ramqExpDate"
    );
    const ramqNumberQuestion = questions.find(
        (question) => question.key == "ramqNumber"
    );
    if (ramqExpDateQuestion && ramqNumberQuestion) {
        if (
            typeof ramqNumberQuestion.answer.rawValue == "undefined" ||
            ramqNumberQuestion.answer.rawValue == ""
        )
            questions.splice(parseInt(ramqExpDateQuestion.index) - 1, 1);
    }

    const questionTrackItems = questions.map((question) => ({
        id: question.index,
        hasError: showErrors
            ? !!extractQuestionError(question, translations)
            : false,
    }));

    const isElementFullyInViewport = (el: HTMLElement | null) => {
        // Check if element is fully in viewport
        if (!el) return false;
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <=
                (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <=
                (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    useEffect(() => {
        const handleScroll = () => {
            const now = Date.now();
            // Skip updating if less than 500ms since last manual focus
            // This prevents uncessary focus changes when scrolling
            if (now - lastManualFocusTime.current < 500) {
                return;
            }

            //The following code is used to track the current question in the viewport
            // and when scrolling, it only focus on the first question that is fully visible
            // This is to prevent the focus from jumping around when multiple questions are visible

            const fullyVisibleQuestions: string[] = [];
            for (const index in questionsRefMap.current) {
                const el = questionsRefMap.current[index];
                if (isElementFullyInViewport(el)) {
                    fullyVisibleQuestions.push(index);
                }
            }

            if (fullyVisibleQuestions.length > 0) {
                fullyVisibleQuestions.sort();
                if (
                    window.scrollY + window.innerHeight >=
                        document.documentElement.scrollHeight &&
                    fullyVisibleQuestions[1]
                ) {
                    setFocusedIndex(fullyVisibleQuestions[1]);
                } else {
                    setFocusedIndex(fullyVisibleQuestions[0]);
                }
            }
        };

        window.addEventListener("wheel", handleScroll);

        handleScroll();

        return () => {
            window.removeEventListener("wheel", handleScroll);
        };
    }, [questions]);

    return (
        <div ref={containerRef} className={styles.holder}>
            <div className="d-flex flex-column container">
                {questions.sort().map((question, index) => (
                    <QuestionBlock
                        key={question.id}
                        question={question}
                        onChange={(answer: any) =>
                            onAnswerChange(question, answer)
                        }
                        focused={focusedIndex === question.index}
                        showError={submitted}
                        onSubmit={() => {
                            scrollToPosition(index + 1);
                        }}
                        onClick={() => scrollToPosition(index)}
                        registerBlock={(blockRef) => {
                            questionsRefMap.current[question.index] = blockRef;
                        }}
                    />
                ))}
                <div className="dual-button-holder flex-end">
                    <Button
                        onClick={onSubmitForm}
                        disabled={showErrors}
                        loading={loading}
                    >
                        {t.completeBtn}
                    </Button>
                </div>

                {showErrors && (
                    <div className={`${styles.errorsHolder} has-error`}>
                        <label>{t.invalidFormError}</label>
                        {errors.map(renderError)}
                    </div>
                )}
            </div>
            <div className={styles.stickyBottom}>
                <div className="container">
                    <div className={styles.trackerScroll}>
                        <Tracker
                            questionsTrack={questionTrackItems}
                            focusedIndex={focusedIndex}
                            scrollToQuestion={scrollToElement}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionnaireForm;
