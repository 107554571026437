import useAuthUser from "hooks/auth-user";
import Appointment, { hasExpiredQuestionnaire } from "models/appointment";
import { getLastAppointment } from "models/user";
import React, {
    createContext,
    ReactNode,
    useState,
    useContext,
    useEffect,
} from "react";

type LastAppointmentContextType = {
    hasToPayLastMissedAppointment: boolean;
    lastAppointment: Appointment | undefined;
    showPaymentForm: boolean;
    setShowPaymentForm: React.Dispatch<React.SetStateAction<boolean>>;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const LastAppointmentContext = createContext<LastAppointmentContextType>(null!);

export const LastAppointmentProvider = (props: {
    children: ReactNode;
}): React.ReactElement => {
    const [authUser] = useAuthUser();
    const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
    const [lastAppointment, setLastAppointment] = useState<
        Appointment | undefined
    >(undefined);
    const [
        hasToPayLastMissedAppointment,
        setHasToPayLastMissedAppointment,
    ] = useState<boolean>(false);

    useEffect(() => {
        if (authUser) {
            setLastAppointment(getLastAppointment(authUser));

            if (lastAppointment) {
                const isScheduled = lastAppointment.status === "SCHEDULED";
                const expiredQuestionnaire = hasExpiredQuestionnaire(
                    lastAppointment
                );
                setHasToPayLastMissedAppointment(
                    expiredQuestionnaire &&
                        !lastAppointment.canRescheduleFreely &&
                        !lastAppointment.shouldRetake &&
                        !lastAppointment.missedAppointmentPaymentStripeId &&
                        isScheduled
                );
            }
        }
    }, [
        authUser,
        lastAppointment?.missedAppointmentPaymentStripeId,
        lastAppointment?.shouldRetake,
        lastAppointment?.canRescheduleFreely,
    ]);

    const contextValue = {
        hasToPayLastMissedAppointment,
        lastAppointment,
        showPaymentForm,
        setShowPaymentForm,
    };

    return (
        <LastAppointmentContext.Provider value={contextValue}>
            {props.children}
        </LastAppointmentContext.Provider>
    );
};

export function useLastAppointment(): LastAppointmentContextType {
    return useContext(LastAppointmentContext);
}
