import React, { useState, useEffect } from "react";
import { XCircle } from "react-feather";

import styles from "./modal.module.css";

interface ModalProps {
    children?: React.ReactNode;
    className?: string;
    visible?: boolean;
    title?: string;
    subtitle?: string;
    isLoading?: boolean;
    showHeader?: boolean;
    onVisibilityChange?: (visible: boolean) => void;
}

const Modal: React.FunctionComponent<ModalProps> = ({
    children,
    title,
    subtitle,
    className,
    visible = false,
    isLoading = false,
    showHeader = true,
    onVisibilityChange,
}: ModalProps) => {
    // States

    const [_visible, _setVisible] = useState(visible);

    // Effects

    useEffect(() => {
        if (onVisibilityChange) {
            onVisibilityChange(_visible);
        }
    }, [_visible]);

    useEffect(() => {
        _setVisible(visible);
    }, [visible]);

    // Rendering

    let rootClass = styles.modal;
    if (className) rootClass += ` ${className}`;

    return (
        <div
            className={`${styles.background} ${_visible ? styles.visible : ""}`}
            onClick={() => !isLoading && _setVisible(false)}
        >
            <div className={rootClass} onClick={(e) => e.stopPropagation()}>
                {showHeader && (
                    <div className={styles.header}>
                        <div className="d-flex flex-column">
                            <span>{title}</span>
                            {subtitle && <span>{subtitle}</span>}
                        </div>
                        <button
                            onClick={() => _setVisible(false)}
                            disabled={isLoading}
                        >
                            <XCircle />
                        </button>
                    </div>
                )}
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );
};

export default Modal;
