import React from "react";

import styles from "./tracker.module.css";

interface questionTrackItem {
    id: string;
    hasError: boolean;
}

interface TrackerProps {
    questionsTrack: questionTrackItem[];
    focusedIndex: string | undefined;
    scrollToQuestion: (index: string) => void;
    showErrors?: boolean;
}

const Tracker: React.FunctionComponent<TrackerProps> = ({
    questionsTrack,
    focusedIndex,
    scrollToQuestion,
}: TrackerProps) => {
    //Since we are not using the id as the index
    //we need to find the index of the current question in focus
    const currentQuestionIndex = questionsTrack.findIndex(
        (item) => item.id === focusedIndex
    );
    return (
        <div className={styles.trackList}>
            {questionsTrack.map((questionTrackItem, index) => (
                <QuestionTrackItem
                    //We have questions with the same id, (e.g. 02b & 17i)
                    //so we need to add the index to the key to make it unique
                    key={questionTrackItem.id + "" + index}
                    questionTrackItem={questionTrackItem}
                    index={index}
                    scrollToQuestion={scrollToQuestion}
                    currentQuestionIndex={currentQuestionIndex}
                />
            ))}
        </div>
    );
};

function QuestionTrackItem({
    questionTrackItem,
    index,
    scrollToQuestion,
    currentQuestionIndex,
}: {
    questionTrackItem: questionTrackItem;
    index: number;
    scrollToQuestion: (index: string) => void;
    currentQuestionIndex: number;
}) {
    let trackItemClassName = styles.trackItem;

    if (index === currentQuestionIndex) {
        trackItemClassName += " " + styles.active;
    } else if (index < currentQuestionIndex) {
        trackItemClassName += " " + styles.done;
    }
    if (questionTrackItem.hasError) {
        trackItemClassName += " " + styles.hasError;
    }

    return (
        <div className={trackItemClassName}>
            <span className={styles.dash} />
            <div
                className={styles.circle}
                onClick={() => {
                    scrollToQuestion(questionTrackItem.id);
                }}
            >
                {questionTrackItem.id}
            </div>
        </div>
    );
}

export default Tracker;
