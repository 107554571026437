import useFeatureManager from "hooks/featureManager";
import { createContext, PropsWithChildren, useContext } from "react";

interface FeatureFlagContextValue {
    isFeatureFlagEnabled: boolean;
}

const FeatureFlagContext = createContext<FeatureFlagContextValue>(
    {} as FeatureFlagContextValue
);

type FeatureFlagContextProviderProps = PropsWithChildren<{
    flagKey: string;
    value: string;
}>;
export function FeatureFlagContextProvider({
    flagKey,
    value,
    children,
}: FeatureFlagContextProviderProps): JSX.Element {
    const [featureManager] = useFeatureManager();

    const isFeatureFlagEnabled =
        featureManager?.isFeatureFlagEnabled(flagKey, value) ?? false;

    return (
        <FeatureFlagContext.Provider
            value={{
                isFeatureFlagEnabled,
            }}
        >
            {children}
        </FeatureFlagContext.Provider>
    );
}

/**
 * Custom hook to determine if a feature flag is enabled.
 * We define the feature flag key and value upon definition of the closest FeatureFlagContextProvider.
 *
 * This hook must be used within a `FeatureFlagContextProvider` to access the feature flag context.
 * If used outside of a `FeatureFlagContextProvider`, it will throw an error.
 *
 * @returns {Object} An object containing a boolean `isFeatureFlagEnabled` indicating if the feature flag is enabled.
 * @throws {Error} If the hook is used outside of a `FeatureFlagContextProvider`.
 */

export function useFeatureFlag(): {
    isFeatureFlagEnabled: boolean;
} {
    const context = useContext(FeatureFlagContext);

    if (!context) {
        throw new Error(
            "useFeatureFlag must be used within a FeatureFlagContextProvider"
        );
    }

    const { isFeatureFlagEnabled } = context;

    return { isFeatureFlagEnabled };
}
