import { ConfigProvider, Form, Select, Space, Tooltip } from "antd";
import enUS from "antd/locale/en_US";
import frCA from "antd/locale/fr_CA";
import { useState } from "react";

import { Button, Card, Field, Modal } from "components";
import { SelectDetails } from "./components/select-details";

import useLanguage from "hooks/language";
import { DeliverySettings } from "../../../../../models";
import { useMyPrEP } from "../../utils/context";
import {
    prepDeliveryDestinationOptions,
    prepDeliveryFrequencyOptions,
} from "../../utils/utils";

import "./delivery-preferences-form.css";

export const DeliveryPreferencesForm: React.FunctionComponent = () => {
    const localizations = useLanguage();
    const {
        translations: {
            prepSection: { deliverySettingsForm: t },
        },
        language,
    } = localizations;

    const {
        updateSettings,
        settings,
        isLoading,
        deliveryPreferencesModalVisible,
        setDeliveryPreferencesModalVisible,
    } = useMyPrEP();

    const [
        deliveryPreferences,
        setDeliveryPreferences,
    ] = useState<DeliverySettings>(settings as DeliverySettings);

    const destinationOptions = prepDeliveryDestinationOptions(localizations);
    const frequencyOptions = prepDeliveryFrequencyOptions(localizations);

    const handleOnSelectChange = (key: string, value: string) => {
        setDeliveryPreferences((prev: DeliverySettings) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleOnInputFieldChange = (
        key: string,
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.target;

        if (key === "zipCode" && value.length >= 7) return;

        setDeliveryPreferences((prev: DeliverySettings) => ({
            ...prev,
            [key]: value,
        }));
    };

    const onSubmit = () => {
        updateSettings(deliveryPreferences);
        setDeliveryPreferencesModalVisible(false);
    };

    return (
        <ConfigProvider locale={language === "en" ? enUS : frCA}>
            <Modal
                visible={deliveryPreferencesModalVisible}
                onVisibilityChange={setDeliveryPreferencesModalVisible}
                isLoading={isLoading}
            >
                <Card>
                    <Card.Header>
                        <h3>{t.title}</h3>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Item name="frequency" preserve={false}>
                                <label>{t.frequency.label}</label>
                                <Select
                                    size="large"
                                    className="select"
                                    defaultValue={deliveryPreferences.frequency}
                                    options={frequencyOptions}
                                    onChange={(value) =>
                                        handleOnSelectChange("frequency", value)
                                    }
                                    optionRender={(option) => {
                                        return (
                                            <Space>
                                                <Tooltip
                                                    title={option.data.details}
                                                >
                                                    <span>
                                                        {option.data.label}
                                                    </span>
                                                </Tooltip>
                                            </Space>
                                        );
                                    }}
                                />

                                <SelectDetails
                                    frequency={deliveryPreferences.frequency}
                                />
                            </Form.Item>

                            <Form.Item name="destination" preserve={false}>
                                <label>{t.destination.label}</label>
                                <Select
                                    size="large"
                                    className="select"
                                    defaultValue={
                                        deliveryPreferences.destination
                                    }
                                    options={destinationOptions}
                                    onChange={(value) =>
                                        handleOnSelectChange(
                                            "destination",
                                            value
                                        )
                                    }
                                    optionRender={(option) => {
                                        return (
                                            <Space>
                                                <Tooltip
                                                    title={option.data.details}
                                                >
                                                    <span>
                                                        {option.data.label}
                                                    </span>
                                                </Tooltip>
                                            </Space>
                                        );
                                    }}
                                />
                                <SelectDetails
                                    destination={
                                        deliveryPreferences.destination
                                    }
                                />
                            </Form.Item>

                            <Form.Item name="address" preserve={false}>
                                <label>{t.address.label}</label>
                                <Field
                                    placeholder={t.address.placeholder}
                                    value={
                                        deliveryPreferences?.address ??
                                        undefined
                                    }
                                    onChange={(e) =>
                                        handleOnInputFieldChange("address", e)
                                    }
                                />
                            </Form.Item>

                            <Form.Item name="city" preserve={false}>
                                <label>{t.city.label}</label>
                                <Field
                                    placeholder={t.city.placeholder}
                                    value={
                                        deliveryPreferences?.city ?? undefined
                                    }
                                    onChange={(e) =>
                                        handleOnInputFieldChange("city", e)
                                    }
                                />
                            </Form.Item>

                            <Form.Item name="zipCode" preserve={false}>
                                <label>{t.zipCode.label}</label>
                                <Field
                                    placeholder={t.zipCode.placeholder}
                                    value={
                                        deliveryPreferences?.zipCode ??
                                        undefined
                                    }
                                    onChange={(e) =>
                                        handleOnInputFieldChange("zipCode", e)
                                    }
                                />
                            </Form.Item>

                            <Form.Item name="province">
                                <label>{t.province.label}</label>
                                <Field
                                    placeholder={t.province.placeholder}
                                    disabled
                                    value="QC"
                                />
                            </Form.Item>

                            <div className="ctaContainer ">
                                <Button
                                    type="secondary"
                                    className="ctaButton"
                                    onClick={() =>
                                        setDeliveryPreferencesModalVisible(
                                            false
                                        )
                                    }
                                    disabled={isLoading}
                                >
                                    {t.cancelDeliverySettingsCTA}
                                </Button>
                                <Button
                                    type="primary"
                                    className="ctaButton"
                                    onClick={onSubmit}
                                    loading={isLoading}
                                    disabled={isLoading}
                                >
                                    {t.saveDeliverySettingsCTA}
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Modal>
        </ConfigProvider>
    );
};
