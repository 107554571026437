import React from "react";
import { Loader } from "react-feather";

import styles from "./button.module.css";

type ButtonType = "primary" | "secondary" | "tertiary";

interface ButtonProps {
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    type?: ButtonType;
    onClick?: () => void;
}

const Button: React.FunctionComponent<ButtonProps> = ({
    children,
    className,
    type = "primary",
    disabled = false,
    loading = false,
    onClick,
}: ButtonProps) => {
    // Handlers

    const _onClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (onClick) onClick();

        event.preventDefault();
        event.stopPropagation();
    };

    // Rendering

    const rootClasses = [styles.button];
    if (type !== "primary") {
        rootClasses.push(styles[type]);
    }
    if (className) {
        rootClasses.push(className);
    }

    return (
        <button
            disabled={disabled || loading}
            className={rootClasses.join(" ")}
            onClick={_onClick}
        >
            {loading && <Loader className="spinorama" />}
            <span>{children}</span>
        </button>
    );
};

export default Button;
