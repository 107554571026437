import {
    Experiment,
    ExperimentClient,
    ExperimentUser,
} from "@amplitude/experiment-js-client";
import { IAnalyticsEventTracker } from "utils/analytics";
import { UserActionEvent } from "utils/analytics/events";
import { generateAnalyticsUserID } from "utils/analytics/user-id";
import { IFeatureManager } from "..";

export enum ExperimentServerZone {
    US = "us",
    EU = "eu",
}

export enum FeatureFlag {
    On = "on",
    Off = "off",
}

export class AmplitudeFlagAndExperimentManager implements IFeatureManager {
    private _experimentClient: ExperimentClient;

    public constructor(
        private readonly deploymentKey: string,
        private readonly experimentServerZone?: ExperimentServerZone,
        private readonly enableDebugger: boolean = false,
        private readonly eventTracker?: IAnalyticsEventTracker
    ) {
        if (!deploymentKey) {
            throw new Error("deploymentKey");
        }

        this._experimentClient = Experiment.initialize(this.deploymentKey, {
            debug: this.enableDebugger,
            serverZone: this.experimentServerZone ?? ExperimentServerZone.EU,
            automaticExposureTracking: !!this.eventTracker,
            exposureTrackingProvider: {
                track: (exposure) => {
                    this.eventTracker?.trackEvent(
                        UserActionEvent.AmplitudeVariantExposure,
                        exposure
                    );
                },
            },
        });
    }

    public async start(): Promise<boolean> {
        try {
            await this._experimentClient.start();

            return true;
        } catch (error) {
            console.error("Cannot start Amplitude SDK");

            return false;
        }
    }

    public async configureUser(email?: string): Promise<void> {
        const experimentUser: ExperimentUser = {};

        if (email) {
            experimentUser.user_id = generateAnalyticsUserID(email);
        }

        await this._experimentClient.fetch(experimentUser);
    }

    public isFeatureFlagEnabled(name: string, expectedValue: string): boolean {
        const flagVariant = this._experimentClient.variant(name);

        return flagVariant.value === expectedValue;
    }
}
