import Axios from "axios";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { DateTimePicker } from "..";
import useAuthUser from "../../hooks/auth-user";
import Appointment, {
    canEdit,
    getMaxRetakeDate,
    getMaxScheduleDate,
    MAX_BOOK_APPOINTMENT_DATE_DAYS,
    MIN_BOOK_APPOINTMENT_HOUR_DELAY,
} from "../../models/appointment";
import PhoneAppointment, {
    MAX_BOOK_PHONE_APPOINTMENT_DATE_DAYS,
} from "../../models/phone-appointment";
import { isAppointment } from "../../utils/appointment-helpers";
import ENDPOINTS from "../../utils/endpoints";
import { AppointmentDatetimePickerProvider } from "./appointment-calendar-context";

interface AppointmentDatetimePickerProps {
    appointment?: Appointment | PhoneAppointment;
    onSelectDate?: (isoDate: string | null) => void;
    className?: string;
    clinicId?: number;
    isPrepEligibilityAppointment?: boolean;
    startDate?: string;
    endDate?: Date;
}

const AppointmentDatetimePicker: React.FunctionComponent<AppointmentDatetimePickerProps> = ({
    appointment,
    onSelectDate,
    className,
    clinicId,
    isPrepEligibilityAppointment,
    startDate,
    endDate,
}: AppointmentDatetimePickerProps) => {
    const [authUser] = useAuthUser();
    const isPhoneAppointment = appointment && !isAppointment(appointment);
    const maybeClinicId = isPhoneAppointment ? undefined : clinicId;
    const maybePhoneAppointmentId =
        isPrepEligibilityAppointment && appointment
            ? appointment.id
            : undefined;

    // states

    const [maxDate, setMaxDate] = useState<Date | undefined>();
    const [openedDates, setOpenedDates] = useState<string[] | undefined>();
    const [availableTimeSlot, setAvailableTimeSlot] = useState<
        string[] | undefined
    >([]);
    const [selectedDate, setSelectedDate] = useState<Date | undefined>();
    const [selectedTime, setSelectedTime] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Effects

    useEffect(() => {
        let calendarMaxDate: Date =
            authUser && authUser.lastUserQuestionnaire
                ? DateTime.fromISO(authUser.lastUserQuestionnaire.created_at)
                      .plus({ days: MAX_BOOK_APPOINTMENT_DATE_DAYS })
                      .toJSDate()
                : DateTime.local()
                      .plus({ days: MAX_BOOK_APPOINTMENT_DATE_DAYS })
                      .toJSDate();

        if (appointment && isAppointment(appointment)) {
            if (canEdit(appointment)) {
                calendarMaxDate = getMaxScheduleDate(appointment);
            } else if (appointment.shouldRetake) {
                calendarMaxDate = getMaxRetakeDate(appointment);
            }
        } else if (isPhoneAppointment) {
            calendarMaxDate = DateTime.local()
                .plus({ days: MAX_BOOK_PHONE_APPOINTMENT_DATE_DAYS })
                .toJSDate();
        }

        setMaxDate(calendarMaxDate);
    }, [authUser, appointment]);

    useEffect(() => {
        fetchOpenedDates(startDate ? new Date(startDate) : new Date());
    }, [appointment]);

    useEffect(() => {
        setSelectedTime(undefined);
        fetchAvailableTime();
    }, [selectedDate]);

    useEffect(() => {
        if (!onSelectDate) return;

        if (!selectedDate || !selectedTime) {
            onSelectDate(null);
            return;
        }

        selectedDate.setHours(12);

        const dateString = selectedDate.toISOString().split("T")[0];
        const dateTimeString = `${dateString}T${selectedTime}:00`;

        // TODO: Use the timezone of the clinic and not hardcoded and maybe move date creation to server
        const date = DateTime.fromISO(dateTimeString, {
            zone: "America/New_York",
        });

        onSelectDate(date.toISO());
    }, [selectedDate, selectedTime]);

    // Network

    const fetchOpenedDates = async (date: Date) => {
        setSelectedDate(undefined);
        setOpenedDates(undefined);
        setIsLoading(true);

        const year = date.getFullYear();
        const month = date.getMonth();
        const url = isPrepEligibilityAppointment
            ? ENDPOINTS.PHARMACIST_APPOINTMENT_AVAILABLE_DATE(
                  year,
                  month,
                  maybePhoneAppointmentId
              )
            : isPhoneAppointment
            ? ENDPOINTS.PHONE_APPOINTMENT_AVAILABLE_DATE(
                  year,
                  month,
                  (appointment as unknown) as PhoneAppointment
              )
            : ENDPOINTS.APPOINTMENT_AVAILABLE_DATE(year, month, maybeClinicId);

        const { data } = await Axios.get(url);

        setIsLoading(false);
        setOpenedDates(data);
    };

    const fetchAvailableTime = async () => {
        setAvailableTimeSlot(undefined);

        if (!selectedDate) {
            setAvailableTimeSlot([]);
            return;
        }

        const url = isPrepEligibilityAppointment
            ? ENDPOINTS.PHARMACIST_APPOINTMENT_AVAILABLE_TIME(
                  selectedDate,
                  maybePhoneAppointmentId
              )
            : isPhoneAppointment
            ? ENDPOINTS.PHONE_APPOINTMENT_AVAILABLE_TIME(
                  selectedDate,
                  (appointment as unknown) as PhoneAppointment
              )
            : ENDPOINTS.APPOINTMENT_AVAILABLE_TIME(selectedDate, maybeClinicId);

        let { data: slots } = await Axios.get(url);

        // Need to remove past available times if date is today
        const selectedDateIsToday = DateTime.fromJSDate(selectedDate).hasSame(
            DateTime.local(),
            "day"
        );
        const filterPredicate = (
            time: string,
            currentAbsTime: number,
            filterAfter?: boolean
        ) => {
            const [hour, minute] = time.split(":");
            const absTime = parseInt(hour) + parseInt(minute) / 60;

            if (filterAfter) return absTime < currentAbsTime;
            return absTime > currentAbsTime;
        };

        if (selectedDateIsToday) {
            const minTime =
                isPhoneAppointment || isPrepEligibilityAppointment
                    ? DateTime.local()
                    : DateTime.local().plus({
                          hours: MIN_BOOK_APPOINTMENT_HOUR_DELAY,
                      });
            const currentAbsTime = minTime.hour + minTime.minute / 60;
            slots = slots.filter((time: string) =>
                filterPredicate(time, currentAbsTime)
            );
        }

        // Need to remove past available times if selected date equals to start date
        if (startDate) {
            const _selectedDate = DateTime.fromJSDate(selectedDate);
            const _startDate = DateTime.fromISO(startDate);
            const areDatesEqual = _selectedDate.hasSame(_startDate, "day");

            if (areDatesEqual) {
                const minTime = DateTime.fromISO(startDate as string);
                const startDateAdsTime = minTime.hour + minTime.minute / 60;
                slots = slots.filter((time: string) =>
                    filterPredicate(time, startDateAdsTime)
                );
            }
        }
        // Need to remove future available times if selected date equals to end date
        if (endDate) {
            const _selectedDate = DateTime.fromJSDate(selectedDate);
            const _endDate = DateTime.fromJSDate(endDate);
            const areDatesEqual = _selectedDate.hasSame(_endDate, "day");

            if (areDatesEqual) {
                const maxTime = DateTime.fromJSDate(endDate);
                const endDateAdsTime = maxTime.hour + maxTime.minute / 60;
                slots = slots.filter((time: string) =>
                    filterPredicate(time, endDateAdsTime, true)
                );
            }
        }
        setAvailableTimeSlot(slots.sort());
    };

    // Rendering

    const minDate = new Date(startDate ?? "");

    minDate.setDate(minDate.getDate() - 1);

    return (
        <AppointmentDatetimePickerProvider isLoadingDates={isLoading}>
            <DateTimePicker
                className={className}
                date={selectedDate}
                minDate={minDate}
                maxDate={endDate ?? maxDate}
                openedDates={openedDates}
                onDateChange={setSelectedDate}
                onTimeChange={setSelectedTime}
                onCalendarNavigation={fetchOpenedDates}
                availableTimeSlots={availableTimeSlot}
            />
        </AppointmentDatetimePickerProvider>
    );
};
export default AppointmentDatetimePicker;
