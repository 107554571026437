//@ts-expect-error Need to import React
import React, { useState } from "react";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { Button, Field } from "../../components";
import useLanguage from "../../hooks/language";
import ENDPOINTS from "../../utils/endpoints";
import ROUTES from "../../utils/routes";
import { useLastAppointment } from "../../context/last-appointment";
import { useHistory } from "react-router-dom";
import Appointment from "../../models/appointment";

const elementOptions = {
    style: {
        base: {
            fontFamily: '"Poppins" sans-serif',
            fontSize: "16px",
            border: "1px solid #1C1C1C",
            fontWeight: "400",
            color: "#1C1C1C",
        },
    },
};

// TODO Create a reusable payment form component for this and the other payment forms..
const PaymentForm = (): React.ReactElement => {
    const { translations } = useLanguage();
    const {
        setShowPaymentForm,
        lastAppointment: appointment,
    } = useLastAppointment();
    const { push } = useHistory();
    const stripe = useStripe();
    const elements = useElements();
    const t = translations.paymentSection;

    // States

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [cardHolderName, setCardHolderName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");

    // Validation

    const enabled =
        cardHolderName.length > 0 &&
        address.length > 0 &&
        city.length > 0 &&
        postalCode.length > 0;

    // Handlers

    const payFees = async (token: any) => {
        if (!appointment) return;

        const body = {
            missedAppointmentPaymentToken: token.id,
        };

        try {
            const { data } = await Axios.put(
                ENDPOINTS.PAY_MISSED_APPPOINTEMENT_FEES(appointment.id),
                body
            );
            if (data) onCompletePayment(data);
        } finally {
            setIsSubmitting(false);
        }
    };

    const onCompletePayment = (updatedAppointemnt: Appointment) => {
        if (appointment)
            appointment.missedAppointmentPaymentStripeId =
                updatedAppointemnt.missedAppointmentPaymentStripeId;
        setShowPaymentForm(false);
        push(ROUTES.SCREENING);
    };

    const onSubmit = async () => {
        if (!stripe || !elements) return;

        setIsSubmitting(true);

        const cardNumberElement = elements.getElement(CardNumberElement);

        if (!cardNumberElement) return;

        const { token, error } = await stripe.createToken(cardNumberElement, {
            name: cardHolderName,
            address_zip: postalCode,
            address_line1: address,
            address_city: city,
        });

        if (error) {
            NotificationManager.error(error.message);
            console.error(error);
            setIsSubmitting(false);
            return;
        }

        if (token) {
            await payFees(token);
            setIsSubmitting(false);
        }
    };

    // Rendering

    return (
        <div>
            <p>
                {translations.appointmentsSection.gatherCreditInfoModal.content}
                <a
                    href={
                        translations.appointmentsSection.creditInfoModal.faqUrl
                    }
                    target="_blank"
                    rel="noreferrer"
                >
                    {
                        translations.appointmentsSection.gatherCreditInfoModal
                            .link
                    }
                </a>
            </p>

            <div className="row">
                <div className="input-holder hundred">
                    <label>{t.cardHolderName}</label>
                    <Field
                        value={cardHolderName}
                        onChange={(e) => setCardHolderName(e.target.value)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="input-holder hundred">
                    <label>{t.cardNumber}</label>
                    <div className="stripe-element-wrapper">
                        <CardNumberElement options={elementOptions} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="input-holder fifty">
                    <label>{t.cardExpirationDate}</label>
                    <div className="stripe-element-wrapper">
                        <CardExpiryElement options={elementOptions} />
                    </div>
                </div>
                <div className="input-holder fifty">
                    <label>{t.cardSecurityCode}</label>
                    <div className="stripe-element-wrapper">
                        <CardCvcElement options={elementOptions} />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="input-holder thirty">
                    <label>{t.cardHolderAddress}</label>
                    <Field
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
                <div className="input-holder thirty">
                    <label>{t.cardHolderCity}</label>
                    <Field
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </div>
                <div className="input-holder thirty">
                    <label>{t.cardHolderZipCode}</label>
                    <Field
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                    />
                </div>
            </div>

            <br />

            <p className="disclaimer">{t.privacyMessage}</p>
            <p className="disclaimer">
                <b>{t.delayDisclaimer}</b>
            </p>

            <div className="dual-button-holder">
                <Button
                    type="secondary"
                    disabled={isSubmitting}
                    onClick={() => setShowPaymentForm(false)}
                >
                    {t.cancelButton}
                </Button>
                <Button
                    loading={isSubmitting}
                    onClick={onSubmit}
                    disabled={!stripe || !enabled}
                >
                    {t.payButtonText}
                </Button>
            </div>
        </div>
    );
};

export default PaymentForm;
