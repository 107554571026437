import googleLogoSrc from "assets/images/google-logo.svg";

import styles from "./google-review-card.module.css";

interface GoogleReviewCardProps {
    comment: string;
    userName: string;
}

export default function GoogleReviewCard({
    comment,
    userName,
}: GoogleReviewCardProps): React.ReactElement {
    return (
        <article className={styles.card}>
            <img src={googleLogoSrc} className={styles.logo} />
            <div className={styles.comment}>
                <span className={styles.rating}></span>
                <p>&ldquo;{comment}&rdquo;</p>
            </div>
            <div className={`d-flex flex-column ${styles.user}`}>
                <strong className={styles.user__name}>{userName}</strong>
                <span>Google Review</span>
            </div>
        </article>
    );
}
