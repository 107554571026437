import React from "react";
import { ArrowsCounterClockwise } from "phosphor-react";
import styles from "./loading-icon.module.css";

const LoadingIcon: React.FunctionComponent<{ className?: string }> = ({
    className = "",
}: {
    className?: string;
}) => <ArrowsCounterClockwise className={`${styles.rotate} ${className}`} />;

export default LoadingIcon;
