import React, { useEffect, useState } from "react";
import useLanguage from "../../../hooks/language";
import { Button, LoadingIcon } from "../../../components";
import { NotificationManager } from "react-notifications";
import Clinic from "../../../models/clinic";
import ClinicList from "../../../components/clinic-list";
import Axios from "axios";
import ENDPOINTS from "../../../utils/endpoints";
import Prep from "./prep";
import { DateTime } from "luxon";
import { MIN_BOOK_APPOINTMENT_HOUR_DELAY } from "models/appointment";
import { useFeatureFlag } from "../feature-flag-context/featureFlagContext";

interface DateSelectionProps {
    onSubmit?: (
        isoDate: string,
        clinicId: number,
        prepDate: string | null
    ) => void;
    loading?: boolean;
    canChoosePrepDate?: boolean;
    wantsPrep?: boolean;
    setWantsPrep: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const DateSelection: React.FunctionComponent<DateSelectionProps> = ({
    onSubmit,
    loading = false,
    canChoosePrepDate,
    wantsPrep,
    setWantsPrep,
}: DateSelectionProps) => {
    const { translations } = useLanguage();
    const t = translations.dateSection;

    // States

    const [isoDate, setIsoDate] = useState<string | null>(null);
    const [clinics, setClinics] = useState<Clinic[]>([]);
    const [isLoadingClinics, setIsloadingClinics] = useState<boolean>(false);
    const [selectedClinic, setSelectedClinic] = useState<number | null>(null);
    const [prepEligibilityDate, setPrepEligibilityDate] = useState<
        string | null
    >(null);
    const [samplingStartDate, setSamplingStartDate] = useState<
        string | undefined
    >(undefined);

    // Effects

    useEffect(() => {
        if (!canChoosePrepDate || prepEligibilityDate || wantsPrep === false)
            fetchClinics();
    }, [canChoosePrepDate, wantsPrep, prepEligibilityDate]);

    const { isFeatureFlagEnabled } = useFeatureFlag();
    // Networking

    const fetchClinics = async () => {
        try {
            const startDate = prepEligibilityDate
                ? DateTime.fromISO(prepEligibilityDate)
                      .plus({
                          hour: MIN_BOOK_APPOINTMENT_HOUR_DELAY,
                      })
                      .toISO()
                : undefined;
            setSamplingStartDate(startDate);
            setIsloadingClinics(true);
            const { data } = await Axios.get(ENDPOINTS.CLINICS, {
                params: {
                    showNextTime: !isFeatureFlagEnabled,
                    startDate,
                },
            });

            setIsloadingClinics(false);
            setClinics(data);
        } catch (error) {
            console.log(error);
            NotificationManager.error(error);
        }
    };

    // Handlers

    const _onSubmit = () => {
        if (onSubmit != null && isoDate != null && selectedClinic != null) {
            onSubmit(isoDate, selectedClinic, prepEligibilityDate);
        }
    };

    const hasMadePrepDecision =
        wantsPrep === false || (wantsPrep && prepEligibilityDate);

    // Rendering

    return (
        <div className="container">
            {canChoosePrepDate ? (
                <h2>{t.titleWithPrep}</h2>
            ) : (
                <h2>{t.header}</h2>
            )}

            {canChoosePrepDate && (
                <Prep
                    setPrepEligibilityDate={setPrepEligibilityDate}
                    wantsPrep={wantsPrep}
                    setWantsPrep={setWantsPrep}
                />
            )}
            {(!canChoosePrepDate || hasMadePrepDecision) && (
                <>
                    <div
                        style={{
                            display: clinics.length > 0 ? "block" : "none",
                        }}
                    >
                        {canChoosePrepDate && (
                            <>
                                <h3>{t.screeningSectionTitle}</h3>
                                <p>{t.screeningSectionDescription}</p>
                            </>
                        )}
                    </div>
                    {isLoadingClinics ? (
                        <>
                            <LoadingIcon /> <span>{t.clinicsAreLoading}</span>
                        </>
                    ) : (
                        <>
                            <ClinicList
                                clinics={clinics}
                                onDateSelect={setIsoDate}
                                onClinicSelect={setSelectedClinic}
                                hasDateSelect={true}
                                startDate={samplingStartDate}
                                style={{
                                    marginLeft: canChoosePrepDate
                                        ? "8px"
                                        : "0px",
                                }}
                            />
                            <div className="dual-button-holder flex-end">
                                <Button
                                    disabled={!isoDate || !selectedClinic}
                                    onClick={_onSubmit}
                                    loading={loading}
                                >
                                    {t.submitButtonText}
                                </Button>
                            </div>
                        </>
                    )}
                </>
            )}
            <br />
        </div>
    );
};

export const Hr: React.FunctionComponent<React.HTMLProps<HTMLHRElement>> = (
    props: React.HTMLProps<HTMLHRElement>
) => <hr style={{ borderTop: "1px solid #BFBFBF" }} {...props} />;

export default DateSelection;
