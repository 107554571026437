import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "components";
import useLanguage from "hooks/language";
import PaymentForm from "./form";
import { useLastAppointment } from "context/last-appointment";

const PUBLIC_STRIPE_KEY = process.env.REACT_APP_PUBLIC_STRIPE_KEY || "null";
const stripePromise = loadStripe(PUBLIC_STRIPE_KEY);

const MissedAppointmentPaymentModal = (): React.ReactElement => {
    const { showPaymentForm, setShowPaymentForm } = useLastAppointment();
    const { translations, language } = useLanguage();
    return (
        <Modal
            title={translations.homePage.payMissedAppointmentFeesPopup.title}
            visible={showPaymentForm}
            onVisibilityChange={setShowPaymentForm}
        >
            <Elements stripe={stripePromise} options={{ locale: language }}>
                <PaymentForm />
            </Elements>
        </Modal>
    );
};

export default MissedAppointmentPaymentModal;
