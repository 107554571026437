/**
 * Shuffles a full array, or a limited set of items given a start and end index
 */
export function shuffle<T>(
    array: T[],
    startIndex = 0,
    endIndex: number = array.length - 1
): T[] {
    if (startIndex < 0 || endIndex > array.length - 1) {
        throw new Error(
            "Provided indexes must be between 0 and the length of the array"
        );
    }

    if (array.length <= 2) {
        return array;
    }

    const itemsToShuffle = array.filter(
        (_, index) => index >= startIndex && index <= endIndex
    );
    const shuffledArray: T[] = [];

    if (startIndex > 0) {
        shuffledArray.unshift(...array.slice(0, startIndex));
    }

    for (let index = itemsToShuffle.length - 1; index > 0; --index) {
        const randomIndex = Math.floor(Math.random() * (index + 1));
        [itemsToShuffle[index], itemsToShuffle[randomIndex]] = [
            itemsToShuffle[randomIndex],
            itemsToShuffle[index],
        ];
    }

    shuffledArray.push(...itemsToShuffle);

    if (endIndex < array.length - 1) {
        shuffledArray.push(...array.slice(endIndex + 1));
    }

    return shuffledArray;
}
