import { useContext } from "react";
import { Translations } from "translations/types";
import AppContext from "../context/app";
import { UserLanguage } from "../utils/types";

type LanguageHook = {
    language: UserLanguage;
    setLanguage: (language: UserLanguage) => void;
    translations: Translations;
};

const useLanguage = (): LanguageHook => {
    const { language, setLanguage, translations } = useContext(AppContext);
    return { language, setLanguage, translations };
};

export default useLanguage;
