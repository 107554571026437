import { Button } from "components";
import { useLastAppointment } from "context/last-appointment";
import useAuthUser from "hooks/auth-user";
import useLanguage from "hooks/language";
import { PREP_STATUS } from "models/prep-status";
import { Loader } from "react-feather";
import { useHistory } from "react-router-dom";
import ROUTES from "utils/routes";
import { useStopPrepService } from "../../utils/context";
import { PrEPStatusHistory } from "../prep-status-history";
import styles from "./prep-status-tab.module.css";

/**
 * The contents of the PrEP status tab depends on the eligibility of the user,
 * taken from their last PrEP status.
 */
export const PrEPStatusTab: React.FunctionComponent = () => {
    const [user] = useAuthUser();

    if (!user) {
        return <Loader className={`spinorama ${styles.loader}`} />;
    } else if (user.lastPrepStatus?.status === PREP_STATUS.ELIGIBLE) {
        return <ActiveSection />;
    } else {
        return <InactiveSection />;
    }
};

/**
 * This section is displayed if the user's latest status is exactly ELIGIBLE.
 * Here they can see they status history and a stop button.
 */
const ActiveSection = () => {
    const {
        translations: {
            prepSection: {
                prepStatus: { active: t },
            },
        },
    } = useLanguage();

    const [user] = useAuthUser();
    const stopPrepService = useStopPrepService();

    const onClick = async () => {
        await stopPrepService();
    };

    return (
        <section className={`container ${styles.status} ${styles.active}`}>
            <h2>{t.title}</h2>
            <hr />
            <p>{t.description}</p>
            <Button
                type="secondary"
                className={styles.stopBtn}
                onClick={onClick}
            >
                {t.btn}
            </Button>
            <h3>{t.history}</h3>
            <hr />
            {user && <PrEPStatusHistory />}
        </section>
    );
};

/**
 * This section is displayed if the user has no PrEP status, or if their latest
 * status is not ELIGIBLE. Here they have a button that already exists at other
 * places in the app (in the nav bar and the home tabs): The button to start
 * the questionnaire+appointment process.
 */
const InactiveSection = () => {
    const {
        translations: {
            prepSection: {
                prepStatus: { inactive: t },
            },
        },
    } = useLanguage();

    const {
        hasToPayLastMissedAppointment,
        setShowPaymentForm,
    } = useLastAppointment();
    const { push } = useHistory();

    const onClick = () => {
        if (hasToPayLastMissedAppointment) setShowPaymentForm(true);
        else push(ROUTES.SCREENING);
    };

    return (
        <section className={`container ${styles.status} ${styles.inactive}`}>
            <h2>{t.title}</h2>
            <hr />
            <p>{t.description}</p>
            <Button onClick={onClick}>{t.btn}</Button>
            <p>{t.disclaimer}</p>
        </section>
    );
};
