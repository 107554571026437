import React from "react";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import useLanguage from "../../hooks/language";
import { Button } from "../../components";
import Appointment from "../../models/appointment";
import GoogleReviewCard from "components/google-review-card";

import styles from "./google-review-modal.module.css";
import { googleReviewLinkForAppointment } from "utils/appointment-helpers";
import useAnalytics from "hooks/analytics";
import { UserActionEvent } from "utils/analytics/events";
import ENDPOINTS from "utils/endpoints";

const USER_PROFILES: { name: string; reviewTextIndex: number }[] = [
    { name: "Jonathan", reviewTextIndex: 0 },
    { name: "Vincent", reviewTextIndex: 1 },
];

interface GoogleReviewModalProps {
    onClose: () => void;
    appointment?: Appointment;
}

const GoogleReviewModal: React.FunctionComponent<GoogleReviewModalProps> = ({
    onClose,
    appointment,
}: GoogleReviewModalProps) => {
    const { translations } = useLanguage();
    const t = translations.appointmentsSection.googleReviewModal;

    const [analytics] = useAnalytics();

    if (!appointment?.clinicId) return null;

    const saveUserPreferenceAboutPublicReviews = async (status: string) => {
        try {
            await Axios.put(
                ENDPOINTS.UPDATE_USER_PUBLIC_REVIEWS(`${appointment.clinicId}`),
                {
                    publicReviewPreference: status,
                }
            );

            NotificationManager.success(
                t.notifications.publicReviewPreferenceSaveSuccess
            );
        } catch (e) {
            NotificationManager.error(
                t.notifications.publicReviewPreferenceSaveError
            );
        }
    };

    const onReviewClinic = async () => {
        analytics
            ?.cdp()
            ?.trackEvent(UserActionEvent.AppointmentPublicReviewClicked);
        const clinicReviewPage = googleReviewLinkForAppointment(appointment);
        window.open(clinicReviewPage, "_blank");
        await saveUserPreferenceAboutPublicReviews("REVIEWED");

        onClose();
    };

    const onRefuseToReviewThisClinicInTheFuture = async () => {
        await saveUserPreferenceAboutPublicReviews("REFUSED");
        onClose();
    };

    return (
        <div className={styles.content}>
            <div className={styles.header}>
                <span className={styles.title}>{t.title}</span>
                <span className={styles.subtitle}>{t.subtitle}</span>
            </div>

            <div className={styles.reviews}>
                {USER_PROFILES.map(({ name, reviewTextIndex }) => (
                    <GoogleReviewCard
                        key={`review-${reviewTextIndex}`}
                        comment={t.reviews[reviewTextIndex]}
                        userName={name}
                    />
                ))}
            </div>
            <div className={styles.actions}>
                <Button onClick={onReviewClinic}>{t.reviewButtonText}</Button>
                <Button onClick={onClose} type="secondary">
                    {t.postponeButtonText}
                </Button>
                <Button
                    onClick={onRefuseToReviewThisClinicInTheFuture}
                    type="tertiary"
                >
                    {t.refuseButtonText}
                </Button>
            </div>
        </div>
    );
};

export default GoogleReviewModal;
