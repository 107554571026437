import {
    createContext,
    MutableRefObject,
    PropsWithChildren,
    useContext,
    useRef,
} from "react";

interface AppointmentDatetimePickerContextValue {
    isLoadingDates: boolean;
    calendarStartDateRef: MutableRefObject<Date | undefined>;
}
const AppointmentDatetimePickerContext = createContext<AppointmentDatetimePickerContextValue>(
    {} as AppointmentDatetimePickerContextValue
);

type AppointmentDatetimePickerContextProviderProps = PropsWithChildren<{
    isLoadingDates: boolean;
}>;
export function AppointmentDatetimePickerProvider({
    isLoadingDates,
    children,
}: AppointmentDatetimePickerContextProviderProps): JSX.Element {
    const calendarStartDateRef = useRef<Date | undefined>(undefined);

    return (
        <AppointmentDatetimePickerContext.Provider
            value={{
                isLoadingDates,
                calendarStartDateRef,
            }}
        >
            {children}
        </AppointmentDatetimePickerContext.Provider>
    );
}

export function useAppointmentDatetimePicker(): {
    calendarStartDate: Date | undefined;
    setCalendarStartDate: (date: Date) => void;
    isLoadingDates: boolean;
} {
    const { calendarStartDateRef, isLoadingDates } = useContext(
        AppointmentDatetimePickerContext
    );

    const setCalendarStartDate = (newDate: Date) => {
        calendarStartDateRef.current = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            1
        );
    };

    return {
        calendarStartDate: calendarStartDateRef.current,
        setCalendarStartDate,
        isLoadingDates,
    };
}
