import Axios from "axios";
import GoogleReviewModal from "./google-review-modal";
import SatisfactionSurveyModal from "./satisfaction-survey-modal";

import styles from "./google-review-modal.module.css";
import Appointment from "models/appointment";
import useLanguage from "hooks/language";
import { useEffect, useState } from "react";
import ENDPOINTS from "utils/endpoints";
import { Modal } from "components";
import { SatisfactionForm } from "components/feedback-questionnaire";

type setModalVisibility = (visibility: boolean) => void;
interface DoubleFeedbackModalProps {
    visible: boolean;
    onVisibilityChange: setModalVisibility;
    modalAppointment?: Appointment;
}

const DoubleFeedbackModal: React.FunctionComponent<DoubleFeedbackModalProps> = ({
    visible,
    onVisibilityChange,
    modalAppointment,
}: DoubleFeedbackModalProps) => {
    const { translations } = useLanguage();
    const t = translations.appointmentsSection;

    const [
        satisfactionSurveyModalVisible,
        setSatisfactionSurveyModalVisible,
    ] = useState(false);
    const [canShowGoogleReviewModal, setCanShowGoogleReviewModal] = useState(
        false
    );
    const [googleReviewModalVisible, setGoogleReviewModalVisible] = useState(
        false
    );

    useEffect(() => {
        const bothModalsAreHidden =
            !satisfactionSurveyModalVisible &&
            (!canShowGoogleReviewModal || !googleReviewModalVisible);
        if (bothModalsAreHidden) {
            // update visibility for outsiders
            onVisibilityChange(false);
        }
    }, [
        satisfactionSurveyModalVisible,
        googleReviewModalVisible,
        canShowGoogleReviewModal,
    ]);

    useEffect(() => {
        // first modal visibility stays consistent with props
        setSatisfactionSurveyModalVisible(visible);
        setGoogleReviewModalVisible(false);
        // refresh user review preferences whenever opening the modal
        if (visible && modalAppointment?.clinicId) {
            const getUserPreferenceForPublicClinicReview = async () => {
                const { data } = await Axios.get<{ clinicId: number }[]>(
                    ENDPOINTS.GET_USER_PUBLIC_REVIEWS
                );

                const userHasClinicPreferenceSaved = data.find(
                    ({ clinicId }) => clinicId == modalAppointment?.clinicId
                );
                return !userHasClinicPreferenceSaved;
            };

            getUserPreferenceForPublicClinicReview()
                .then((canShowModal) =>
                    setCanShowGoogleReviewModal(canShowModal)
                )
                .catch(() => setCanShowGoogleReviewModal(true));
        }
    }, [visible, modalAppointment?.clinicId]);

    const askEligibleUserForPublicReview = ({
        satisfactionLevel,
        recommendationProbability,
    }: SatisfactionForm) => {
        if (canShowGoogleReviewModal) {
            const isOverSatisfactionThreshold = (value: number | null) =>
                (value ?? 0) > 7;
            const isUserSatisfied =
                isOverSatisfactionThreshold(satisfactionLevel) &&
                isOverSatisfactionThreshold(recommendationProbability);
            setGoogleReviewModalVisible(isUserSatisfied);
        }
    };

    return visible ? (
        <>
            <Modal
                title={t.feedbackModal.title}
                subtitle={t.feedbackModal.letUsKnowHowItWent}
                visible={visible && satisfactionSurveyModalVisible}
                onVisibilityChange={setSatisfactionSurveyModalVisible}
            >
                <SatisfactionSurveyModal
                    appointment={modalAppointment}
                    afterSubmit={askEligibleUserForPublicReview}
                    onClose={() => {
                        setSatisfactionSurveyModalVisible(false);
                    }}
                />
            </Modal>

            {/* Google review modal */}
            {canShowGoogleReviewModal && (
                <Modal
                    showHeader={false}
                    visible={visible && googleReviewModalVisible}
                    onVisibilityChange={setGoogleReviewModalVisible}
                    className={styles.widerModal}
                >
                    <GoogleReviewModal
                        appointment={modalAppointment}
                        onClose={() => {
                            setGoogleReviewModalVisible(false);
                        }}
                    />
                </Modal>
            )}
        </>
    ) : (
        <></>
    );
};

export default DoubleFeedbackModal;
