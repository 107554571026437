import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import PaymentForm from "./form";
import useLanguage from "../../../../hooks/language";
import useAuthUser from "hooks/auth-user";

const PUBLIC_STRIPE_KEY = process.env.REACT_APP_PUBLIC_STRIPE_KEY || "null";
const stripePromise = loadStripe(PUBLIC_STRIPE_KEY);

interface PaymentProps {
    onCompletePayment?: () => void;
    onSkipPaymentClicked: () => void;
}

const Payment: React.FunctionComponent<PaymentProps> = ({
    onCompletePayment,
    onSkipPaymentClicked,
}: PaymentProps) => {
    const [authUser] = useAuthUser();
    const { translations, language } = useLanguage();
    const localization = translations.upfrontPaymentSection;

    const isUserRamqAvailable = !!authUser?.ramqNumber;

    // Rendering

    return (
        <div className="container">
            {isUserRamqAvailable ? (
                <div>
                    <h2>{localization.ramqHeader}</h2>
                    <p>{localization.ramqChargeExplanation}</p>
                </div>
            ) : (
                <div>
                    <h2>{localization.noRamqHeader}</h2>
                    <p>{localization.noRamqChargeExplanation}</p>
                    <p>{localization.noRamqChargeExplanationNote}</p>
                </div>
            )}

            <Elements stripe={stripePromise} options={{ locale: language }}>
                <PaymentForm
                    onCompletePayment={onCompletePayment}
                    onSkipPaymentClicked={onSkipPaymentClicked}
                    showCashPaymentOption={isUserRamqAvailable}
                />
            </Elements>
        </div>
    );
};

export default Payment;
