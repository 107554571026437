import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import {
    StripeCardCvcElementOptions,
    StripeCardExpiryElementOptions,
    StripeCardNumberElementOptions,
    StripeElementStyle,
} from "@stripe/stripe-js";
import Axios from "axios";
import useAnalytics from "hooks/analytics";
import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import { UserActionEvent } from "utils/analytics/events";
import { Button } from "../../../../components";
import useAuthUser from "../../../../hooks/auth-user";
import useLanguage from "../../../../hooks/language";
import ENDPOINTS from "../../../../utils/endpoints";
import styles from "./payment.module.css";

const cardElementStyle: StripeElementStyle = {
    base: {
        backgroundColor: "#FFFFFF",
        fontFamily: "Poppins, sans-serif",
        fontSize: "14px",
        fontWeight: 400,
        iconColor: "#CDCDCD",
        textAlign: "justify",
    },
};

interface PaymentFormProps {
    onCompletePayment?: () => void;
    onSkipPaymentClicked: () => void;
    showCashPaymentOption: boolean;
}

enum ScreeningPaymentType {
    ApplePay = "Apple Pay",
    Cash = "Cash",
    CreditCard = "Credit Card",
    GooglePay = "Google Pay",
}

const PaymentForm: React.FunctionComponent<PaymentFormProps> = ({
    onCompletePayment,
    onSkipPaymentClicked,
    showCashPaymentOption,
}: PaymentFormProps) => {
    const { translations } = useLanguage();
    const [authUser] = useAuthUser();
    const [analytics] = useAnalytics();
    const stripe = useStripe();
    const elements = useElements();
    const localization = translations.upfrontPaymentSection;

    const cardNumberOptions: StripeCardNumberElementOptions = {
        iconStyle: "solid",
        placeholder: localization.cardNumber,
        showIcon: true,
        style: cardElementStyle,
    };

    const cardExpiryOptions: StripeCardExpiryElementOptions = {
        placeholder: localization.cardExpiry,
        style: cardElementStyle,
    };

    const cardCvcOptions: StripeCardCvcElementOptions = {
        placeholder: localization.cardCvc,
        style: cardElementStyle,
    };

    // States

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCardCvcValid, setIsCardCvcValid] = useState(false);
    const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
    const [isCardNumberValid, setIsCardNumberValid] = useState(false);

    // Network

    const payQuestionnaire = async (tokenID: string) => {
        if (!authUser || !authUser.lastUserQuestionnaire) {
            return;
        }

        const body = { token: tokenID };
        const hasRamq = !!authUser.ramqNumber;

        try {
            const url = hasRamq
                ? ENDPOINTS.USER_PAYMENT_INFO
                : ENDPOINTS.PAY_USER_QUESTIONNAIRE(
                      authUser.lastUserQuestionnaire.id
                  );

            const response = await Axios.put(url, body);

            analytics
                ?.cdp()
                ?.trackEvent(UserActionEvent.ScreeningPaymentSubmitted, {
                    method: ScreeningPaymentType.CreditCard,
                });

            if (!hasRamq) {
                authUser.lastUserQuestionnaire = response.data;
            }

            onCompletePayment?.();
        } finally {
            setIsSubmitting(false);
        }
    };

    // Handlers

    const onSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        setIsSubmitting(true);

        const cardNumberElement = elements.getElement(CardNumberElement);

        if (!cardNumberElement) {
            return;
        }

        const { token, error } = await stripe.createToken(cardNumberElement);

        if (error) {
            NotificationManager.error(error.message);
            console.error(error);
            setIsSubmitting(false);
        } else if (token) {
            await payQuestionnaire(token.id);
            setIsSubmitting(false);
        }
    };

    const onPayWithCashClicked = (): void => {
        analytics
            ?.cdp()
            ?.trackEvent(UserActionEvent.ScreeningPaymentSubmitted, {
                method: ScreeningPaymentType.Cash,
            });
        onSkipPaymentClicked();
    };

    // Rendering
    return (
        <div>
            <label htmlFor="card-element">{localization.cardLabel}</label>
            <div id="card-element" className={styles["card-container"]}>
                <div className={styles["card-container__item-main"]}>
                    <CardNumberElement
                        options={cardNumberOptions}
                        onChange={(e) =>
                            setIsCardNumberValid(!e.error && e.complete)
                        }
                    />
                </div>
                <div className={styles["card-container__item"]}>
                    <CardExpiryElement
                        options={cardExpiryOptions}
                        onChange={(e) =>
                            setIsCardExpiryValid(!e.error && e.complete)
                        }
                    />
                </div>
                <div className={styles["card-container__item-end"]}>
                    <CardCvcElement
                        options={cardCvcOptions}
                        onChange={(e) =>
                            setIsCardCvcValid(!e.error && e.complete)
                        }
                    />
                </div>
            </div>
            {showCashPaymentOption && (
                <div
                    className={`${styles["payment-options"]} ${styles["single-line"]}`}
                >
                    <span>{localization.paymentOptionIntro}</span>
                    <a onClick={() => onPayWithCashClicked()}>
                        {localization.paymentOptionCash}
                    </a>
                </div>
            )}
            <div className={styles["save-button"]}>
                <Button
                    loading={isSubmitting}
                    onClick={onSubmit}
                    disabled={
                        !stripe ||
                        !isCardNumberValid ||
                        !isCardExpiryValid ||
                        !isCardCvcValid ||
                        !authUser
                    }
                >
                    {localization.payButtonText}
                </Button>
            </div>
        </div>
    );
};

export default PaymentForm;
