import React, { useState } from "react";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import useLanguage from "../../hooks/language";
import { AppointmentDateTimePicker, Button } from "../../components";
import PhoneAppointment, {
    getLinkedAppointmentDateTime,
} from "../../models/phone-appointment";
import ENDPOINTS from "../../utils/endpoints";
import useAuthUser from "hooks/auth-user";
import { DateTime } from "luxon";
import { MIN_BOOK_APPOINTMENT_HOUR_DELAY } from "models/appointment";

interface SchedulePhoneAppointmentModalProps {
    onClose?: () => void;
    onConfirm?: () => void;
    appointment?: PhoneAppointment;
}

const SchedulePhoneAppointmentModal: React.FunctionComponent<SchedulePhoneAppointmentModalProps> = ({
    appointment,
    onConfirm,
    onClose,
}: SchedulePhoneAppointmentModalProps) => {
    const { translations } = useLanguage();
    const t = translations.appointmentsSection;
    const [authUser] = useAuthUser();

    // states
    const [loading, setLoading] = useState<boolean>(false);
    const [isoDate, setIsoDate] = useState<string | null>(null);

    // Network

    const updateAppointment = async () => {
        if (!appointment) return;

        setLoading(true);

        try {
            await Axios.put(
                ENDPOINTS.SCHEDULE_PHONE_APPOINTMENT(appointment.id),
                {
                    startAt: isoDate,
                }
            );
            if (onConfirm) await onConfirm();
            NotificationManager.success(
                translations.notification.rescheduledAppointment
            );
        } finally {
            setLoading(false);
        }
    };

    // For the date of the PrEP eligibility call, the date selected must be prior to the date of the linked appointment
    let endDate;
    if (
        appointment?.type === "PREP_ELIGIBILITY" &&
        appointment.linkableType === "appointment"
    ) {
        const date = getLinkedAppointmentDateTime(
            appointment.linkableId,
            authUser
        );
        if (date)
            endDate = DateTime.fromISO(date)
                .minus({ hour: MIN_BOOK_APPOINTMENT_HOUR_DELAY })
                .toJSDate();
    }

    // Rendering

    return (
        // TODO Feriel le clinic Id est hardcoder le changer pour la clinic du appointment
        <div>
            <AppointmentDateTimePicker
                appointment={appointment}
                onSelectDate={setIsoDate}
                clinicId={1}
                endDate={endDate ?? undefined}
                startDate={
                    appointment?.isAboutPrep
                        ? DateTime.now()
                              .plus({ hours: MIN_BOOK_APPOINTMENT_HOUR_DELAY })
                              .toISO()
                        : undefined
                }
                isPrepEligibilityAppointment={appointment?.isAboutPrep}
            />
            <br />
            <div className="dual-button-holder">
                <Button onClick={onClose} type="secondary">
                    {t.scheduleModal.exitButtonText}
                </Button>
                <Button
                    disabled={!isoDate}
                    loading={loading}
                    onClick={updateAppointment}
                >
                    {t.scheduleModal.confirmButtonText}
                </Button>
            </div>
        </div>
    );
};

export default SchedulePhoneAppointmentModal;
